import AppBar from '@material-ui/core/AppBar'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import React from 'react'
import { makeStyles, useTheme } from '@material-ui/styles'
import logo from '../../static/image/pixelsearch_logo.png'

const useStyles = makeStyles({
  logo: {
    display: 'flex',
    alignItems: 'center',
    margin: '16px 0'
  },
  logoText: {
    marginRight: '8px',
    fontFamily: 'Quicksand, Helvetica, sans-serif'
  }
})

export default function NavBar() {
  const theme = useTheme()
  const classes = useStyles()
  return (
    <AppBar color='default' position='static'>
      <Container>
        <a
          style={{ textDecoration: 'none', color: theme.palette.text.primary }}
          href={'/'}>
          <div className={classes.logo}>
            <Typography variant='h4' className={classes.logoText}>
              PixelSearch
            </Typography>
            <img height='24' src={logo} alt=''/>
          </div>
        </a>
      </Container>
    </AppBar>
  )
}